import React, { useState, useEffect } from 'react';
import "../../assets/css/style.scss";
import { Delete_User, GET_USERDATA, Organizer_list } from '../../api/api';
import CustomTable from '../../component/Tables/CustomTable';
import ConfirmModal from '../../component/confirmDelete';
import { Delete, Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PAGE_SIZE = 10;

const fetchUsers = async (
  searchText,
  currentPage,
  pageSize,
  setUserList,
  setNumberOfElements
) => {
  try {
    const response = await Organizer_list({
      name: searchText,
      pageNumber: currentPage + 1,
      pageSize,
    });
    if (response?.status === 200) {
      console.log(response?.data)
      setUserList(response.data.rows);
      setNumberOfElements(response.data.count);
    }
  } catch (error) {
    console.error("Failed to fetch users:", error);
  }
};

const OrganizerPaymentDetails = () => {
  const [searchText, setSearchText] = useState("");
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE);
  const [totalElements, setTotalElements] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const navigate = useNavigate();

  const columns = [
    //    { accessorKey: 'id', header: 'ID' },
    { accessorKey: 'organizerName', header: 'Organizer Name' },
    // { accessorKey: 'email', header: 'Email' },
    { accessorKey: 'country', header: 'Country' },
    { accessorKey: 'state', header: 'State' },
    { accessorKey: 'type', header: 'Organizer Type' },
    { accessorKey: 'gstStatus', header: 'GST Registration Status ' },
    {
      accessorKey: "actions",
      header: "Action",
      cell: ({ row }) => (
        <div className='icon-group flex gap-1'>
           <IconButton
            onClick={() => handleEdit(row?.createdBy)}
          >
            <Edit fontSize="medium" />
          </IconButton>
          <IconButton
            className="table-icon"
            onClick={() => openDeleteModal(row)}
          >
            <Delete fontSize="medium" />
          </IconButton>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setShowLoader(true);
      await fetchUsers(
        searchText,
        currentPage,
        rowsPerPage,
        setUserList,
        setTotalElements
      );
      setShowLoader(false);
    };
    fetchData();
  }, [currentPage, rowsPerPage, searchText]);

  const handleSearch = (value) => {
    setSearchText(value);
    setCurrentPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleEdit = (id) => navigate(`${id}`);

  const handleDelete = async (id) => {
    const DeleteUser = await Delete_User(id);
    if (DeleteUser?.status == 200) {
      handleSearch(searchText);
    } else {
      alert(DeleteUser?.error);
    }
  };

  const openDeleteModal = (item) => {
    setItemToDelete(item);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (itemToDelete) {
      await handleDelete(itemToDelete?.createdBy);
      setShowDeleteModal(false);
    }
  };

  return (
    <div className="content-body">
      <div className="dashboard-content">
        <div className="top-content text-gray-500 text-2xl font-medium my-2">
          Organizer Payment Details
        </div>
        <div className='user-management-table'>
          <CustomTable
            btnName={"Invite User"}
            rows={userList}
            columns={columns}
            handleAdd={() => navigate('/users/add-user')}
            count={totalElements}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={currentPage}
            rowsPerPage={rowsPerPage}
            filterText={searchText}
            setFilterText={setSearchText}
          />
        </div>
        {showDeleteModal && (
          <ConfirmModal
            show={showDeleteModal}
            handleClose={() => setShowDeleteModal(false)}
            handleConfirm={confirmDelete}
            deleteDataName={"User"}
          />
        )}
      </div>
    </div>
  );
};

export default OrganizerPaymentDetails;
