import React, { useEffect, useState } from 'react'
import "../../assets/css/style.scss";
import BankCard from '../../component/BankCard';
import { useParams } from 'react-router-dom';
import { GetBankDetailsOrganizer } from '../../api/api';

const fetchOrganiserBankDetails = async (
  id,setBankData
) => {
  try {
    const response = await GetBankDetailsOrganizer(id);
    if (response?.status === 200) {
      console.log(response?.data)
      setBankData(response.data);
    }
  } catch (error) {
    console.error("Failed to fetch bank data:", error);
  }
};

const ParticularOrganizerDetails=()=> {
const [bankData,setBankData]=useState([]);
    const {id}=useParams();

    useEffect(() => {
        const fetchBankData = async () => {
          await fetchOrganiserBankDetails(id,setBankData)
        };
        fetchBankData();
      }, [id]);
  return (
    <div className="content-body">
    <div className="dashboard-content">
      <div className="top-content text-gray-500 text-2xl font-medium my-2">
        User Management
      </div>
     
     <div className="bg-white p-6 shadow-md rounded-md space-y-4">
          <div className="">
            <div className="text-lg font-semibold text-gray-700">
              Bank Accounts with Associated Events
            </div>
          </div>
          <div className="overflow-auto  space-y-4  [&::-webkit-scrollbar]:w-1  [&::-webkit-scrollbar-track]:bg-gray-100  [&::-webkit-scrollbar-thumb]:bg-gray-500  dark:[&::-webkit-scrollbar-track]:bg-neutral-700  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
            <BankCard bankData={bankData}   />
            
         
          </div>

          
        </div>
        </div>
        </div>
 
  )
}

export default ParticularOrganizerDetails