import { Tabs, Button } from "@mantine/core";
import React, { useEffect, useState } from "react";
import EditorConvertToHTML from "../../component/Eventdetail/EditorConvertToHTML";
import { Get_News_Detail, Update_News_Detail } from "../../api/api";

function News() {
  const [editorValues, setEditorValues] = useState({
    runnerUpdate: "",
    organizerUpdate: "",
    generalUpdate: "",
  });
  const [activeTab, setActiveTab] = useState("runnerUpdate");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeEditTab, setActiveEditTab] = useState(null);

  const fetchNews = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await Get_News_Detail();
      if (response?.data) {
        const { runnerUpdate, organizerUpdate, generalUpdate } = response.data;
        setEditorValues({ runnerUpdate, organizerUpdate, generalUpdate });
      }
    } catch (error) {
      setError("Failed to load news.");
      console.error("Failed to fetch news:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);

  const updateNews = async (tab) => {
    setLoading(true);
    setError(null);
    try {
      const payload = { [tab]: editorValues[tab] };
      await Update_News_Detail(payload);
      fetchNews();
      setActiveEditTab(null); 
    } catch (error) {
      setError("Failed to save news.");
      console.error("Failed to save news:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditorChange = (tab, value) => {
    setEditorValues((prev) => ({ ...prev, [tab]: value }));
  };

  const toggleEditMode = (tab) => {
    if (activeEditTab === tab) {
      updateNews(tab);
    } else {
      setActiveEditTab(tab); 
    }
  };

  const cancelEdit = () => {
    fetchNews(); 
    setActiveEditTab(null);
  };

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
  };

  return (
    <div className="content-body">
      <div className="dashboard-content">
        <div className="top-content text-gray-500 text-2xl font-medium my-2">
          News & Updates
        </div>
        {loading && <p>Loading...</p>}
        {error && <p className="text-red-500">{error}</p>}
        {!loading && !error && (
          <Tabs
            className="mt-10"
            value={activeTab}
            onChange={handleTabChange}
            variant="outline"
            radius="md"
          >
            <Tabs.List>
              <Tabs.Tab value="runnerUpdate">Updates for Runners</Tabs.Tab>
              <Tabs.Tab value="organizerUpdate">Updates for Organizers</Tabs.Tab>
              <Tabs.Tab value="generalUpdate">General Updates</Tabs.Tab>
            </Tabs.List>

            {["runnerUpdate", "organizerUpdate", "generalUpdate"].map((tab) => (
              <Tabs.Panel key={tab} value={tab} pt="md">
                <div className="flex justify-between items-center">
                  <p>
                    {tab === "runnerUpdate" && "Latest updates and announcements for runners."}
                    {tab === "organizerUpdate" && "Important news and resources for event organizers."}
                    {tab === "generalUpdate" && "General updates and industry news."}
                  </p>
                  <div>
                    {activeEditTab === tab && (
                      <Button
                        variant="outline"
                        size="xs"
                        onClick={cancelEdit}
                        className="mr-2"
                      >
                        Cancel
                      </Button>
                    )}
                    <Button
                      variant="outline"
                      size="xs"
                      onClick={() => toggleEditMode(tab)}
                    >
                      {activeEditTab === tab ? "Save" : "Edit"}
                    </Button>
                  </div>
                </div>
                <div className="bg-white p-3 mt-2">
                  <EditorConvertToHTML
                    value={editorValues[tab]}
                    onChange={(value) => handleEditorChange(tab, value)}
                    readOnly={activeEditTab !== tab}
                  />
                </div>
              </Tabs.Panel>
            ))}
          </Tabs>
        )}
      </div>
    </div>
  );
}

export default News;
