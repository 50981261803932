import React, { useState, useEffect } from 'react';
import {
    Drawer, Button, TextInput, NumberInput, SegmentedControl, Accordion, Textarea, Group, Select
} from '@mantine/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CurrenciesList } from '../../service/constant';
import { IconsManifest } from 'react-icons/lib';

const TicketForm = ({ isOpen, onClose, ticketData, onSave, isEdit ,id}) => {
    const [ticketType, setTicketType] = useState(ticketData?.isTicketType || 'Paid');
    const [currency, setCurrency] = useState(ticketData?.currencySymbol || '');
    const [ticketPrice, setTicketPrice] = useState(ticketData?.ticketPrice || 0);
    const [minDonationAmount, setMinDonationAmount] = useState(ticketData?.minDonationAmount || 0);
    const [saleStartDate, setSaleStartDate] = useState(ticketData?.startDate ? new Date(ticketData.startDate) : new Date());
    const [saleEndDate, setSaleEndDate] = useState(ticketData?.endDate ? new Date(ticketData.endDate) : new Date());
    const [ticketName, setTicketName] = useState(ticketData?.ticketName || '');
    const [ticketQuantity, setTicketQuantity] = useState(ticketData?.ticketquantity || 0);
    const [minBooking, setMinBooking] = useState(ticketData?.minBooking || 1);
    const [maxBooking, setMaxBooking] = useState(ticketData?.maxBooking || 1);
    const [description, setDescription] = useState(ticketData?.ticketDescription || '');
    const [message, setMessage] = useState(ticketData?.attendeeMessage || '');

    const [errors, setErrors] = useState({});

    useEffect(() => {
        setErrors({})
        if (isEdit && ticketData) {
            setTicketType(ticketData.isTicketType);
            setCurrency(ticketData.currencySymbol);
            setTicketPrice(ticketData.ticketPrice);
            setMinDonationAmount(ticketData.minDonationAmount);
            setSaleStartDate(new Date(ticketData.startDate));
            setSaleEndDate(new Date(ticketData.endDate));
            setTicketName(ticketData.ticketName);
            setTicketQuantity(ticketData.ticketquantity);
            setMinBooking(ticketData.minBooking);
            setMaxBooking(ticketData.maxBooking);
            setDescription(ticketData.ticketDescription);
            setMessage(ticketData.attendeeMessage);
        } else {
            setTicketType('Paid');
            setCurrency('');
            setTicketPrice(0);
            setMinDonationAmount(0);
            setSaleStartDate(new Date());
            setSaleEndDate(new Date());
            setTicketName('');
            setTicketQuantity(1);
            setMinBooking(1);
            setMaxBooking(1);
            setDescription('');
            setMessage('');
        }
    }, [isEdit, ticketData]);

    const validateForm = () => {
        const newErrors = {};

        if (!ticketName?.trim()) newErrors.ticketName = "Ticket name is required";
        if (ticketType !== 'Free' && !currency) newErrors.currency = "Currency is required";
        if (ticketType === 'Paid' && ticketPrice <= 0) newErrors.ticketPrice = "Ticket price must be greater than zero";
        if (ticketType === 'Donation' && minDonationAmount <= 0) newErrors.minDonationAmount = "Minimum donation amount must be greater than zero";
        if (saleStartDate >= saleEndDate) newErrors.saleDates = "Sale start date must be before the end date";
        if (ticketQuantity < 1) newErrors.ticketQuantity = "Ticket quantity must be at least 1";
        if (minBooking < 1) newErrors.minBooking = "Minimum booking must be at least 1";
        if (maxBooking < minBooking) newErrors.maxBooking = "Maximum booking must be greater than or equal to minimum booking";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!validateForm()) {
            return;
        }

        const payload = {
            ticketName,
            isTicketType: ticketType,
            ticketquantity: ticketQuantity,
            ticketPrice,
            startDate: saleStartDate.toISOString(),
            endDate: saleEndDate.toISOString(),
            payFee: ticketType === 'Free' ? 'Buyer' : 'Me',
            paymentFee: ticketType === 'Free' ? 'Buyer' : 'Me',
            ticketDescription: description,
            attendeeMessage: message,
            currencySymbol: currency,
            minBooking,
            maxBooking,
            minDonationAmount,
            startTime: saleStartDate.toLocaleTimeString(),
            endTime: saleEndDate.toLocaleTimeString()
        };

        if (isEdit) {
            onSave(ticketData?.id, payload);
        } else {
            onSave(id,payload);
        }

        onClose();
    };

    return (
        <Drawer
            position='right'
            opened={isOpen}
            onClose={onClose}
            title={<span className='font-semibold text-lg' style={{ color: "#0b3d6a" }} >{isEdit ? "Edit Ticket" : "Add Ticket"}</span>}
            padding="xl"
            size="xl"
            classNames={{
                root: 'overflow-y-auto',
                drawer: 'overflow-y-auto',
            }}
        >
            <form onSubmit={handleSubmit} className="space-y-4">
                <TextInput
                    label={<span className='text-base'>Ticket Name</span>}
                    placeholder="Ticket Name"
                    name="ticketName"
                    value={ticketName}
                    onChange={(e) => setTicketName(e.target.value)}
                    error={errors.ticketName}
                />
                <label className='text-base font-medium'> Ticket Type : </label>
                <SegmentedControl
                    label="Ticket Type"
                    data={[
                        { label: 'Paid', value: 'Paid' },
                        { label: 'Free', value: 'Free' },
                        { label: 'Donation', value: 'Donation' },
                    ]}
                    name="ticketType"
                    value={ticketType}
                    onChange={setTicketType}
                    className='mx-3'
                />
                <Group>
                    <NumberInput
                        label={<span className='text-base'>Ticket Quantity</span>}
                        min={0}
                        name="ticketQuantity"
                        value={ticketQuantity}
                        onChange={(value) => setTicketQuantity(value)}
                        error={errors.ticketQuantity}
                    />
                    {ticketType !== 'Free' && (
                        <>
                            <Select
                                label={<span className='text-base'>Currency</span>}
                                placeholder="Select a currency"
                                value={currency}
                                onChange={setCurrency}
                                data={CurrenciesList
                                    && CurrenciesList.sort((a, b) => a.name.localeCompare(b.name)).map(
                                        (country) => ({
                                            value: country.cc,
                                            label: `${country.cc} - (${country.name})`,
                                        })
                                    )
                                }
                                required
                                classNames={{
                                    root: 'w-20',
                                    dropdown: 'custom-dropdown',
                                }}
                                error={errors.currency}
                            />
                            {ticketType === 'Paid' ? (
                                <NumberInput
                                    label={<span className='text-base'>Enter price</span>}
                                    placeholder="Enter price"
                                    value={ticketPrice}
                                    onChange={(value) => {
                                        if (value === null || value >= 0) {
                                            setTicketPrice(value);
                                        }
                                    }}
                                    hideControls
                                    error={errors.ticketPrice}
                                />
                            ) : (
                                <NumberInput
                                    label={<span className='text-base'>Minimum Donation Amount</span>}
                                    placeholder="Enter minimum donation amount"
                                    value={minDonationAmount}
                                    onChange={(value) => {
                                        if (value === null || value >= 0) {
                                            setMinDonationAmount(value);
                                        }
                                    }}
                                    hideControls
                                    error={errors.minDonationAmount}
                                />
                            )}
                            <div className='text-center'>
                                <label className='text-base font-medium'>Money to you</label>
                                <p className='my-1 text-blue-600 text-xl'>0</p>
                            </div>
                        </>
                    )}
                </Group>
                <div className='flex gap-4'>
                    <div>
                        <label className="block text-base font-medium text-gray-700">Ticket sale starts from</label>
                        <DatePicker
                            selected={saleStartDate}
                            onChange={(date) => setSaleStartDate(date)}
                            showTimeSelect
                            dateFormat="Pp"
                            className="w-full text-sm border-gray-300 rounded-md p-2 border"
                        />
                    </div>
                    <div>
                        <label className="block text-base font-medium text-gray-700">Ticket sale ends at</label>
                        <DatePicker
                            selected={saleEndDate}
                            onChange={(date) => setSaleEndDate(date)}
                            showTimeSelect
                            dateFormat="Pp"
                            className="w-full text-sm border-gray-300 rounded-md p-2 border"
                        />
                        {errors.saleDates && (
                            <p className="text-red-500 text-sm">{errors.saleDates}</p>
                        )}
                    </div>
                </div>
                <Accordion>
                    <Accordion.Item value="advanced-settings">
                        <Accordion.Control>Advanced settings</Accordion.Control>
                        <Accordion.Panel>
                            <NumberInput
                                className='my-2'
                                label={<span className='text-base'>Minimum per booking</span>}
                                min={1}
                                name="minBooking"
                                value={minBooking}
                                onChange={(value) => setMinBooking(value)}
                                error={errors.minBooking}
                            />
                            <NumberInput
                                className='my-2'
                                label={<span className='text-base'>Maximum per booking</span>}
                                min={1}
                                name="maxBooking"
                                value={maxBooking}
                                onChange={(value) => setMaxBooking(value)}
                                error={errors.maxBooking}
                            />
                            <Textarea
                                className='my-2'
                                label={<span className='text-base'>Ticket Description</span>}
                                name="description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            <Textarea
                                label={<span className='text-base'>Message to Attendee</span>}
                                className='my-2'
                                name="message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
                <div className="flex justify-end space-x-4">
                    <Button variant="outline" color='#0b3d6a' onClick={onClose}>
                        Cancel
                    </Button>
                    <Button color='#0b3d6a' type="submit">Save</Button>
                </div>
            </form>
        </Drawer>
    );
};

export default TicketForm;
