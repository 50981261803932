import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
} from "@mui/material";
import { Button, Group } from "@mantine/core";
import "./CustomTable.scss";

const CustomTable = ({
  rows = [],
  columns = [],
  handleAdd = () => {},
  btnName = "",
  count = 0,
  onPageChange,
  onRowsPerPageChange,
  page = 0,
  rowsPerPage = 10,
  filterText = "",
  setFilterText = () => {},
}) => {

  const displayCount = Math.min(rowsPerPage, rows.length);
  const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, count);

  return (
    <div className="ticket-table">
      <div>
        <TableContainer className="bg-white p-3 table-contain border rounded-md">
          <div className="flex justify-between">
            <TextField
              label="Filter list..."
              variant="outlined"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              className="w-25 my-2 rounded-md"
            />
            {btnName && (
              <Group position="center">
                <Button onClick={handleAdd} variant="outline" color="#0b3d6a">
                  {btnName}
                </Button>
              </Group>
            )}
          </div>

          <Table>
            {columns.length > 0 && (
              <TableHead>
                <TableRow>
                <TableCell>S.No</TableCell>
                  {columns.map((column, index) => (
                    <TableCell key={index}>{column.header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {rows.map((row, rowIndex) => (
                <TableRow style={{ height: "100px" }} key={rowIndex}>
                  <TableCell>{rowIndex+startRow}</TableCell>
                  {columns.map((column, colIndex) => (
                    <TableCell style={{ fontSize: "18px" }} key={colIndex}>
                      {column.cell ? (
                        <column.cell value={row[column.accessorKey]} row={row} />
                      ) : (
                        row[column.accessorKey]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <div className="pagination flex justify-between">
            <div>
              Showing {startRow} - {endRow} of {count} row(s).
            </div>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
            />
          </div>
        </TableContainer>
      </div>
    </div>
  );
};

export default CustomTable;
