import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom"; // Import Link and useLocation
import asseticon from "../../assets/images/asset-icon.svg";
import channelicon from "../../assets/images/channel-icon.svg";
import USER_ICON from "../../assets/images/user.svg";
import backicon from "../../assets/images/back-icon.svg";
import "../../assets/css/style.scss";
import EventIcon from '@mui/icons-material/Event';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ReportIcon from '@mui/icons-material/Report';
import PersonIcon from '@mui/icons-material/Person'; 
import DeleteIcon from '@mui/icons-material/Delete';
import WalletIcon from '@mui/icons-material/Wallet';
import ReceiptIcon from '@mui/icons-material/Receipt';   
import DashboardIcon from '@mui/icons-material/Dashboard';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { NewspaperOutlined } from "@mui/icons-material";
function SlideBar() {
  const [menuStatus, setMenuStatus] = useState(false);
  const location = useLocation(); // Get the current location from react-router-dom
  const [activeItem, setActiveItem] = useState(""); // State to keep track of the active item

  const backMenu = () => {
    if (menuStatus) {
      setMenuStatus(false);
    } else {
      setMenuStatus(true);
    }
  };
  const [eventDataFromStorage, seteventDataFromStorage] = useState(
    JSON.parse(localStorage.getItem("userdetail"))
);
console.log();
  return (
    <div className={menuStatus ? "sidebar-menu menu-icon" : "sidebar-menu"}>
      <div className="back-menu ">
        <a onClick={backMenu}>
          <img src={backicon} alt="Back Icon" />
        </a>
      </div>
      <div className="menu  ">
        <ul>
          <li
            className={
              location.pathname == "/dashboard"
                ? "active"
                : ""
            }
          >
            <Link to="/dashboard">
              <img src={asseticon} alt="dashboard Icon" />
              <span>Dashboard</span>
            </Link>
          </li>
          <li
            className={
              location.pathname === "/organizer-listing" ||
                location.pathname == "/detailpage" ||
                location.pathname == "/eventdetail" ||
                location.pathname == "/Detailpage"
                ? "active"
                : ""
            }
          >
            <Link to="/organizer-listing">
              {/* <img src={channelicon} alt="channel Icon" /> */}
              <EventIcon sx={{ color: 'lightgrey', fontSize:"30px" }}  />
              <span>Event Management</span>
            </Link>
          </li>
          <li
            className={
              location.pathname.includes("users")
                ? "active"
                : ""
            }
          >
            <Link to="/users">
            <ManageAccountsIcon sx={{ color: 'lightgrey', fontSize:"30px" }}  />
              <span>User Management</span>
            </Link>
          </li>
       
          <li className={location.pathname === "/reports" ||location.pathname === "/reports" ? "active" : ""}>
            <Link to="/reports">
            <ReportIcon sx={{ color: 'lightgrey', fontSize:"30px" }}  />
              <span>Reports</span>
              </Link>
          </li>
          <li
            className={
              location.pathname.includes("financial-management")
                ? "active"
                : ""
            }
          >
            <Link to="/financial-management">
            <AccountBalanceIcon sx={{ color: 'lightgrey', fontSize: '25px' }} />
              <span>Financial Management</span>
            </Link>
          </li>
          <li
            className={
              location.pathname.includes("kyc")
                ? "active"
                : ""
            }
          >
            <Link to="/kyc">
            <PersonIcon sx={{ color: 'lightgrey', fontSize: '25px' }} />
              <span>KYC Detail</span>
            </Link>
          </li>
          <li className={location.pathname == "/delete-user" ? "active" : ""}>
            <Link to="/delete-user" >
            <DeleteIcon sx={{ color: 'lightgrey', fontSize: '25px' }} />
              <span>Delete User</span>
            </Link>
          </li>
          <li className={location.pathname.startsWith("/organizer-payment-mode") ? "active" : ""}>
            <Link to="/organizer-payment-mode" >
            <WalletIcon sx={{ color: 'lightgrey', fontSize: '25px' }} />
              <span>Organiser Payment Mode</span>
            </Link>
          </li>
          <li className={location.pathname.startsWith("/payment-details") ? "active" : ""}>
            <Link to="/payment-details" >
            <ReceiptIcon sx={{ color: 'lightgrey', fontSize: '25px' }} />
              <span>Payment Details</span>
            </Link>
          </li>

          <li className={location.pathname.startsWith("/verified-user") ? "active" : ""}>
            <Link to="/verified-user" >
            <ReceiptIcon sx={{ color: 'lightgrey', fontSize: '25px' }} />
              <span>Verified User</span>
            </Link>
          </li>

         { eventDataFromStorage?.email == "admin@test.com" &&
          <li className={location.pathname == "/admin-user-dashboard" ? "active" : ""}>
            <Link to="/admin-user-dashboard" >
            <DashboardIcon sx={{ color: 'lightgrey', fontSize: '25px' }} />
              <span>Admin Dashboard</span>
            </Link>
          </li>}
          {/* <li className={location.pathname === "/inventory" ? "active" : ""}>
            <Link to="/inventory">
              <img src={management} alt="Management Icon" />
              <span>Inventory Management</span>
            </Link>
          </li> */}

          {/* <li
            className={location.pathname === "/master-settings" ? "active" : ""}
          >
            <Link to="/master-settings">
              <img src={master} alt="Master Settings" />
              <span>Master Settings</span>
            </Link>
          </li>
          <li className={location.pathname === "/store-front" ? "active" : ""}>
            <Link to="/store-front">
              <img src={storefrontIcon} alt="Store Front" />
              <span>Store Front</span>
            </Link>
          </li> */}
          <li className={location.pathname.startsWith("/news") ? "active" : ""}>
            <Link to="/news" >
            <NewspaperOutlined sx={{ color: 'lightgrey', fontSize: '25px' }} />
              <span>News & Updates</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SlideBar;
