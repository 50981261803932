import React, { useState, useEffect } from 'react';
import "../../assets/css/style.scss";
import { Delete_User, GET_USERDATA, Organizer_list, VerifiedStatusChanged } from '../../api/api';
import CustomTable from '../../component/Tables/CustomTable';
import ConfirmModal from '../../component/confirmDelete';
import { Delete, Edit } from '@mui/icons-material';
import { IconButton, Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const PAGE_SIZE = 10;

const fetchUsers = async (
  searchText,
  currentPage,
  pageSize,
  setUserList,
  setNumberOfElements
) => {
  try {
    const response = await Organizer_list({
      name: searchText,
      pageNumber: currentPage + 1,
      pageSize,
    });
    if (response?.status === 200) {
      setUserList(response.data.rows);
      setNumberOfElements(response.data.count);
    }
  } catch (error) {
    console.error("Failed to fetch users:", error);
  }
};

const VerifiedUser = () => {
  const [searchText, setSearchText] = useState("");
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE);
  const [totalElements, setTotalElements] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [isVerifiedStatus, setVerifiedStatus] = useState();
  const navigate = useNavigate();
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const columns = [
    //    { accessorKey: 'id', header: 'ID' },
    { accessorKey: 'organizerName', header: 'Organizer Name' },
    // { accessorKey: 'email', header: 'Email' },
    { accessorKey: 'country', header: 'Country' },
    { accessorKey: 'state', header: 'State' },
    { accessorKey: 'type', header: 'Organizer Type' },
    { accessorKey: 'gstStatus', header: 'GST Registration Status ' },
    {
      accessorKey: "actions",
      header: "Verified Status",
      cell: ({ row }) => {
        const handleToggle = async (event, createdBy) => {
            const newStatus = event.target.checked;
            const payload = { isVerified: newStatus };
        
            try {
              const handleStatus = await VerifiedStatusChanged(createdBy, payload);
              if (handleStatus?.status === 200) {
                setVerifiedStatus(newStatus)
                toast.success('Changed Status Successfully');
              } else {
                alert(handleStatus?.error);
              }
            } catch (error) {
              console.error("Error updating status:", error);
            }
          };
        return(
            <div className='icon-group flex gap-1'>     
            <Switch
              {...label}
              checked={row?.isVerified}
              onChange={(e) => handleToggle(e, row?.createdBy)}
              className="transition-all duration-300 ease-in-out"
            />
          </div>
        )
    
    },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setShowLoader(true);
      await fetchUsers(
        searchText,
        currentPage,
        rowsPerPage,
        setUserList,
        setTotalElements
      );
      setShowLoader(false);
    };
    fetchData();
  }, [currentPage, rowsPerPage, searchText,isVerifiedStatus]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  return (
    <div className="content-body">
      <div className="dashboard-content">
        <div className="top-content text-gray-500 text-2xl font-medium my-2">
          Organiser Verified Status
        </div>
        <div className='user-management-table'>
          <CustomTable
            // btnName={"Invite User"}
            rows={userList}
            columns={columns}
            handleAdd={() => navigate('/users/add-user')}
            count={totalElements}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={currentPage}
            rowsPerPage={rowsPerPage}
            filterText={searchText}
            setFilterText={setSearchText}
          />
        </div>
      </div>
    </div>
  );
};

export default VerifiedUser;
