import React, { useState, useEffect } from "react";
import { AddBookingCharges, BookingCharges, EditPayment, FM_Data, GetBankDetailsOrganizer, GetPaymentDetails, SendPayment, updateBooking } from "../../api/api";
import "../../assets/css/style.scss";
import CustomTable from "../../component/Tables/CustomTable";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import CustomModal from "../../component/CustomModal";
import { toast } from "react-toastify";
import NcustomTable from "../../component/Tables/NcustomTable";
import { useParams } from "react-router-dom";
import PaymentModel from "../../component/CustomModal/PaymentModel";


const fetchOrganiserBankDetails = async (
  id,setBankData
) => {
  try {
    const response = await GetBankDetailsOrganizer(id);
    if (response?.status === 200) {
      
      setBankData(response.data);
    }
  } catch (error) {
    console.error("Failed to fetch bank data:", error);
  }
};

const OrganizerEventPayment = () => {
const {event,id}=useParams();
const [bankData,setBankData]=useState([]);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [orginazerList, setOrginazerList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  

  useEffect(() => {
          const fetchBankData = async () => {
            await fetchOrganiserBankDetails(id,setBankData)
          };
          fetchBankData();
        }, [id]);

  const get_Paymentdetails = async () => {
    setShowLoader(true);
    let _id=getEventId();
    const orginazerResponse = await GetPaymentDetails(_id);
    if (orginazerResponse?.status === 200) {
      setOrginazerList(orginazerResponse?.data);
      setTotalPages(Math.ceil(orginazerResponse?.data.length / pageSize));
    }
    setShowLoader(false);
  };

  useEffect(() => {
    get_Paymentdetails();
  }, [id,currentPage]);

  const handleEdit = (item) => {
    setEditItem(item);
    setIsEdit(true);
    setShowEditModal(true);
  };

  const updatePayment = async (data) => {
    setShowLoader(true);
    const response = await EditPayment(editItem.id, data);
    if (response?.status === 200) {
     await get_Paymentdetails();
      toast.success("Payment edit successfully");
    } else {
      
      toast.error(response?.data?.error);
    }
    setShowLoader(false);
    setShowEditModal(false);
    reset();
  };
  const getEventId=()=>{
    if(id)
    {
      let eventDetails=localStorage.getItem("eventDetail");
      if(eventDetails)
      {
        eventDetails = JSON.parse(eventDetails);
      return id===eventDetails?.createdBy && eventDetails?.eventName===event.replaceAll("-"," ")?eventDetails?.id:null
      }
    }
  }

  const addPayment = async (data) => {
    setShowLoader(true);
    let payload={
      "AccountHolderName":data?.AccountHolderName,
     "paymentId":data?.paymentId,
     "AmountPaid":data?.AmountPaid,
     "BankdetailId":data?.BankdetailId,
     "eventId":getEventId(),
     "createdBy":id
      }
    const response = await SendPayment(payload);
    if (response?.status === 200) {
      await get_Paymentdetails();
      toast.success("Payment sent successfully");
    } else {
      
      toast.error(response?.data?.error);
    }
    setShowLoader(false);
    setShowEditModal(false);
    reset();
  };

  const columns = [
    { accessorKey: "AccountHolderName", header: "Account Holder Name" },
    { accessorKey: "paymentId", header: "Payment Id"},
    { accessorKey: "AmountPaid", header: "Amount Paid"},
    { accessorKey: "bankDetail", header: "Bank Name", cell: ({ row }) => row?.bankDetails?.[0]?.BankName || "N/A",},
    {
      accessorKey: "actions",
      header: "Action",
      cell: ({ row }) => (
        <div className='icon-group flex gap-1'>
          <IconButton onClick={() => handleEdit(row)}>
            <Edit fontSize="medium" />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div className="content-body">
      <div className="dashboard-content">
        <div className="top-content">
          <div className="left-side text-gray-500 text-2xl font-medium my-2">
         {event.replaceAll('-'," ")}
          </div>
          <div className="right-side"></div>
        </div>
        <div className="orginazer-setup-table my-4">
          <NcustomTable
            btnName="Send Payment"
            rows={orginazerList}
            columns={columns}
            reset={reset}
            handleAdd={() => {
              setEditItem(null);
              setIsEdit(false);
              setShowEditModal(true);
            }}
          />
        </div>
      </div>
      <PaymentModel
        isOpen={showEditModal}
        bankData={bankData}
        setOpen={setShowEditModal}
        handleSubmit={handleSubmit}
        onSubmit={isEdit ? updatePayment : addPayment}
        errors={errors}
        register={register}
        title={isEdit ? "Edit Payment" : "Send Payment"}
        reset={reset}
        editItem={editItem}
      
      />
    </div>
  );
};

export default OrganizerEventPayment;
