import React, { useEffect } from "react";
import { Modal, Button, Form, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./modal.scss";
import { TextInput } from "@mantine/core";

const PaymentModel = ({
  editItem,
  isOpen,
  setOpen,
  handleSubmit,
  onSubmit,
  errors,
  register,
  title,
  reset,
  bankData
}) => {
  useEffect(() => {
    if (editItem) {
      reset(editItem);
    } else {
      reset({
        AccountHolderName: "",
        BankdetailId: "",
        paymentId: "",
        AmountPaid: "",
      });
    }
  }, [editItem, reset]);

  return (
    <Modal show={isOpen} onHide={() => setOpen(false)} centered>
      <Modal.Body className="ModalBody">
        <Modal.Title className="ModalTitle">{title}</Modal.Title>
        <Form>
          <Row className=" mt-3 w-full">
            <TextInput
              label="Account Holder Name"
              {...register("AccountHolderName", { required: true })}
              defaultValue={editItem ? editItem?.AccountHolderName : ""}
            />
          </Row>
          {errors.AccountHolderName && (
            <span className="text-danger">This field is required</span>
          )}
          <Row className="   w-full">
           
            <div className="my-2 ">
            <label className="block text-[15px]  font-medium text-gray-900 ">Bank Details</label>
            <select  
              {...register("BankdetailId", {
                required: "This field is required",
              })}  className="w-full border text-sm border-gray-100 rounded-md px-2 py-2 " >
                <option value="">Select</option>
                {bankData?.map((item)=>
                    <option value={item?.id}>{item?.BankName}</option>)}
            </select>
            </div>
          </Row>
          {errors.BankdetailId && (
            <span className="text-danger">This field is required</span>
          )}
          <Row className=" w-full">
            <TextInput
              label="Payment Id"
              {...register("paymentId", { required: true })}
              defaultValue={editItem ? editItem?.paymentId : ""}
            />
          </Row>
          {errors.paymentId && (
            <span className="text-danger">This field is required</span>
          )}
          <Row className="w-full">
            <TextInput
              label="Amount Paid"
              type="number"
              {...register("AmountPaid", { required: true })}
              defaultValue={editItem ? editItem?.Tax : ""}
            />
          </Row>
          {errors.AmountPaid && (
            <span className="text-danger">This field is required</span>
          )}
          <div className="flex my-3 gap-2">
            <Button
              className="SubmitButton btn"
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>
            <Button
              className="CancelButton btn btn-default mx-2"
              onClick={() => {
                reset();
                setOpen(false);
              }}
              type="button"
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentModel;
