import React, { useEffect, useState } from 'react';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./paymentpop.scss"
import { Button } from '@mantine/core';
const PaymentModal = ({ isOpen, onClose, singledata, onSubmit }) => {
    const [formData, setFormData] = useState(singledata);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    // const handleSubmit = () => {
    //     onSubmit(formData);
    //     onClose(); // Close the modal after submitting
    // };
    useEffect(() => {
        setFormData(singledata)
    }, [singledata])
    return (
        <Modal show={isOpen===singledata?.id} onHide={onClose} className=" bg-transparent  "  centered>
            {/* <Modal.Header closeButton className="ModalHeader">
               
            </Modal.Header> */}
            <Modal.Body className="ModalBody">

                <Modal.Title className="ModalTitle">Bank Details</Modal.Title>

                <Form >
                    <Row className='my-3'>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Bank Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="organizerName"
                                    readOnly={true}
                                    value={formData?.BankName}
                                    // onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>IfSC Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="country"
                                    value={formData?.IfscCode}
                                    // onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Account Holder Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="type"
                                    readOnly={true}
                                    value={formData?.AccountHolderName}
                                    // onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Account Type</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="gstStatus"
                                    value={formData?.AccountType}
                                    // onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                   
                    {/* <div className='flex gap-3'>
                    <Button color='#0b3d6a'  onClick={() => handleSubmit(formData)}>
                        Submit
                    </Button>
                    <Button color='#0b3d6a' variant='outline' onClick={() => onClose()} type="button">
                        Cancel
                    </Button>
                    </div> */}
                
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default PaymentModal;
