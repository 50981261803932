import React, { useState } from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  IconButton,
} from "@mui/material";
import { Edit, Delete, Label } from "@mui/icons-material";
import "./CustomTable.scss"; // Ensure your SCSS file is correctly imported for styling
import { Button, Group } from "@mantine/core";
import TicketForm from "../tickets/TicketForm";

const NcustomTable = ({ rows, columns, handleAdd, btnName }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterText, setFilterText] = React.useState("");

  // Filtering logic
  const filteredRows = rows.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(filterText.toLowerCase())
    )
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startRow = page * rowsPerPage + 1;


  return (
    <div className="ticket-table">
      <div>
        <TableContainer className="  bg-white p-3 table-contain border rounded-md">
          <div className="flex justify-between">
            <TextField
              label="Filter list..."
              variant="outlined"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              className="w-25 my-2 rounded-md "
            />
            {btnName && <Group position="center">
              <Button onClick={() => handleAdd()} variant="outline" color="#0b3d6a">
                {btnName}
              </Button>

              {/* <Button onClick={() => handleEdit({ ticketName: 'Sample Ticket', ticketType: 'Paid', ticketQuantity: 100, ticketPrice: 50, saleStartDate: new Date(), saleEndDate: new Date(), payFee: 'Buyer', minBooking: 1, maxBooking: 5, description: 'Sample Description', message: 'Sample Message' })}>Edit Ticket</Button> */}
            </Group>}

            {/* <Button variant='outline' style={{border:"1px solid #0b3d6a",color:"#0b3d6a"}}>
      ADD Ticket
    </Button> */}
          </div>

          <Table>
            {columns[0].header && <TableHead>
              <TableRow >
                <TableCell>S.No</TableCell>
                {columns.map((column, index) => (
                  <TableCell key={index}>{column.header}</TableCell>
                ))}
                {/* <TableCell>Actions</TableCell> */}
              </TableRow>
            </TableHead>}
            <TableBody>
              {filteredRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => (
                  <TableRow style={{ height: "100px" }} key={rowIndex}>
                    <TableCell>{rowIndex+startRow}</TableCell>
                    {columns.map((column, colIndex) => (
                      <TableCell style={{ fontSize: "18px" }} key={colIndex}>
                        {column.cell ? (
                          <column.cell value={row[column.accessorKey]} row={row} />
                        ) : (
                          row[column.accessorKey]
                        )}
                      </TableCell>
                    ))}

                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <div className=" pagination flex justify-between">
            <div>
              Showing {Math.min(rowsPerPage, filteredRows.length)} of{" "}
              {filteredRows.length} row(s).
            </div>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={filteredRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </TableContainer>
      </div>
    </div>
  );
};

export default NcustomTable;
