import React, { useEffect, useState } from 'react'
import { BsBank } from "react-icons/bs";
import PaymentModal from '../PaymentPopup/paymentpop';
import { IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';
const BankCard = ({bankData}) => {
    const [events, setEvents] = useState([]);
    const [visibleCount, setVisibleCount] = useState(3);
     const [singleData,setSingleData]=useState(null);
     const [modalOpen,setModalOpen]=useState(null);

    const handleShowMore = () => {
        setVisibleCount((prev) => Math.min(prev + 3, events.length));
      };

      const handleCloseModal = () => setModalOpen();
      useEffect(() => {
        const allEvents = bankData?.flatMap((bank) =>
          bank.BankEvents.map((be) => be.CreateEvent)
        );
        setEvents(allEvents);
      }, [bankData]);
  return (
    <div>
    {bankData?.map((data, index) => (
      <div
        key={index}
        className="p-4 border border-gray-300 rounded-md flex items-center justify-between"
      >
        <div className="flex  w-full  justify-between ">
          <div className=' flex items-start'>
          <div className="text-[#063675] text-2xl mr-4">
            <BsBank />
          </div>
          <div>
            <div className="flex  items-center gap-3">
              <span className="font-medium text-gray-800">
                {data?.BankName}
                 
                ({data?.AccountNumber?.toString()})
              </span> 
               {data?.DefaultPayment && (
                <span className="text-green-500 border-green-500 text-sm border-2 rounded-full px-2">
                  Default
                </span>
              )}
            </div>
            <div className="flex  flex-wrap gap-2 mt-2">
              {data.BankEvents.slice(0, visibleCount).map((bankEvent, idx) => (
                <span
                  key={idx}
                  className=" flex items-center bg-blue-100 text-[#063675] px-3 py-1 rounded-full text-sm"
                >
                  {bankEvent.CreateEvent?.displayEventName}

                  
                </span>
              ))}
              {visibleCount < data.BankEvents.length && (
                <button
                  onClick={handleShowMore}
                  className="bg-gray-200 text-[#063675] px-3 py-1 rounded-full text-sm"
                >
                  +{data.BankEvents.length - visibleCount} more
                </button>
              )}
            </div>
          </div>
          </div>
          <div className='   '>
          <IconButton
            className="table-icon mx-3 cursor-pointer"
            onClick={() => {
              setSingleData(data);
              setModalOpen(data?.id);
            }}
          >
            <Edit fontSize="medium" />
          </IconButton>
         
          </div>
        </div>
        {/* <div className="  flex flex-col md:flex-row gap-3">
          {!data?.DefaultPayment && (
            <button className="text-[#063675] text-xs md:text-base border-[#063675] border p-1 md:px-2 py-1 rounded-sm">
              + Make Default
            </button>
          )}

          <button
            onClick={() => {
              setIsOpen(true);
              setBankId(data.id);
            }}
            className="border-[#063675] text-[#063675] text-xs md:text-base font-semibold border md:px-4 py-2 p-1rounded-md hover:bg-[#063675] hover:text-white"
          >
            + Link Event
          </button>

          <Modal
            isOpen={isOpen}
            onClose={handleClose}
            Action={submit}
            title="Manage Bank Accounts"
          >
            <Multiselectoptn
              options={eventData}
              selected={selectedOptions}
              onChange={setSelectedOptions}
              placeholder="Choose your options"
              bankData={undefined}
            />
          </Modal>
        </div> */}
      </div>
     ))} 
      <div>
          <PaymentModal 
           isOpen={modalOpen}
            onClose={handleCloseModal}
            singledata={singleData}
              />
          </div>
  </div>
  )
}

export default BankCard