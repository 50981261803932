import React, { useState, useEffect } from 'react';
import {
    Drawer, Button, NumberInput, Select,
    TextInput, MultiSelect
} from '@mantine/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DiscountForm = ({ isOpen, onClose, onSave, isEdit, ticketData, id, allTickets }) => {
    const [discountType, setDiscountType] = useState('FLAT');
    const [discountPercent, setDiscountPercent] = useState(0);
    const [noOfDiscounts, setNoOfDiscounts] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [ticketId, setTicketId] = useState([]);
    const [Code, setCode] = useState('');
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (isOpen) {
            if (!isEdit) {
                // Reset fields if not in edit mode
                setDiscountType('FLAT');
                setDiscountPercent(0);
                setNoOfDiscounts(0);
                setStartDate(new Date());
                setEndDate(new Date());
                setTicketId([]);
                setCode('');
            } else if (ticketData) {
                // Populate fields if in edit mode and ticketData is available
                setDiscountType(ticketData.type || 'FLAT');
                setDiscountPercent(ticketData.discountPercent || 0);
                setNoOfDiscounts(ticketData.noOfDiscounts || 0);
                setStartDate(ticketData.startDate ? new Date(ticketData.startDate) : new Date());
                setEndDate(ticketData.endDate ? new Date(ticketData.endDate) : new Date());
                setTicketId(ticketData.TicketDiscounts.map(td => td.ticketId) || []);
                setCode(ticketData.code || '');
            }
        }
    }, [isOpen, isEdit, ticketData]);

    const validateForm = () => {
        const newErrors = {};

        if (discountType === 'CODE' && !Code?.trim()) newErrors.Code = "Discount code is required";
        if (discountPercent === null || discountPercent <= 0) newErrors.discountPercent = "Discount percent must be greater than zero";
        if (noOfDiscounts === null || noOfDiscounts <= 0) newErrors.noOfDiscounts = "Number of discounts must be greater than zero";
        if (startDate >= endDate) newErrors.dates = "Start date must be before the end date";
        if (ticketId.length === 0) newErrors.ticketId = "At least one ticket must be selected";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSave = (event) => {
        event.preventDefault();

        if (!validateForm()) {
            return;
        }

        if (isEdit) {
            const addpayload = {
                tickets: ticketId.map(id => ({ id })),
                updatePayload: {
                    type: discountType,
                    discountPercent,
                    noOfDiscounts,
                    code: Code,
                    eventId: id,
                    startDate: startDate,
                    endDate: endDate,
                    // Replace with actual event ID
                    // status: true
                }
            };
            onSave(ticketData?.id, addpayload);
        } else {
            const payload = {
                type: discountType,
                discountPercent,
                noOfDiscounts,
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                ticketIds: ticketId,
                code: Code,
                eventId: id // Replace with actual event ID
            };
            onSave(payload);
        }
        onClose();
    };

    return (
        <Drawer
            position='right'
            opened={isOpen}
            onClose={onClose}
            title={<span className='font-semibold text-lg' style={{ color: "#0b3d6a" }}>{isEdit ? "Edit Discount" : "Add Discount"}</span>}
            padding="xl"
            size="xl"
            classNames={{
                root: 'overflow-y-auto',
                drawer: 'overflow-y-auto',
            }}
        >
            <div className='min-h-[80vh]'>
                <form className="space-y-10">
                    <Select
                        label="Discount Type"
                        placeholder="Select"
                        data={['CODE', 'FLAT']}
                        value={discountType}
                        onChange={setDiscountType}
                    />
                    {discountType === "CODE" &&
                        <TextInput
                            label={<span className='text-base'>Discount Code</span>}
                            value={Code}
                            placeholder="Enter Discount Code"
                            onChange={(e) => setCode(e.target.value)}
                            error={errors.Code}
                        />
                    }
                    <NumberInput
                        label={<span className='text-base'>Discount Percent (Calculated per ticket)</span>}
                        min={0}
                        value={discountPercent}
                        onChange={setDiscountPercent}
                        error={errors.discountPercent}
                    />
                    <NumberInput
                        label={<span className='text-base'>No. of Discounts</span>}
                        min={0}
                        value={noOfDiscounts}
                        onChange={setNoOfDiscounts}
                        error={errors.noOfDiscounts}
                    />
                    <div className='flex gap-4'>
                        <div>
                            <label className="block text-base font-medium text-gray-700">Discount can be Availed from</label>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                showTimeSelect
                                dateFormat="Pp"
                                className="w-full text-sm border-gray-300 rounded-md p-2 border"
                            />
                        </div>
                        <div>
                            <label className="block text-base font-medium text-gray-700">Discount can be Availed Till</label>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                showTimeSelect
                                dateFormat="Pp"
                                className="w-full text-sm border-gray-300 rounded-md p-2 border"
                            />
                            {errors.dates && (
                                <p className="text-red-500 text-sm">{errors.dates}</p>
                            )}
                        </div>
                    </div>
                    <MultiSelect
                        label="Ticket"
                        placeholder="Select"
                        data={allTickets?.map(ticket => ({
                            value: ticket.id,
                            label: ticket.ticketName,
                        }))}
                        value={ticketId}
                        onChange={setTicketId}
                        error={errors.ticketId}
                    />
                </form>
            </div>
            <div className="flex justify-end space-x-4">
                <Button variant="outline" color='#0b3d6a' onClick={onClose}>
                    Cancel
                </Button>
                <Button color='#0b3d6a' type="submit" onClick={handleSave}>Save</Button>
            </div>
        </Drawer>
    );
};

export default DiscountForm;
